<template>
  <main>
    <div v-if="loading" id="preloader">
      <div class="preloader">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
    <div class="container-fluid">
      <!-- <div class="row"> -->
      <div class="login-section-wrapper">
        <div class="brand-wrapper">
          <img src="@/assets/logo1.png" class="logo" alt="https://www.innovativetoll.com" />
        </div>
        <div class="login-wrapper my-auto">
          <h2 class="login-title">Sign Up</h2>
          <p class="text-gray-400"><em>Sign-Up to get started</em></p>
          <Form @submit="handleSignup" :validation-schema="schema" class="user">
            <div class="form-group">
              <label>Name</label>
              <Field name="name" type="text" class="form-control form-control-user" placeholder="Enter name..." />
              <ErrorMessage name="name" class="text-danger p-3" />
            </div>
            <div class="form-group">
              <label>Phone</label>
              <Field name="phone" type="text" class="form-control form-control-user" placeholder="Enter phone..." />
              <ErrorMessage name="phone" class="text-danger p-3" />
            </div>
            <div class="form-group">
              <label>Email</label>
              <Field name="email" type="text" class="form-control form-control-user"
                placeholder="Enter Email Address..." />
              <ErrorMessage name="email" class="text-danger p-3" />
            </div>
            <div class="form-group">
              <label>Address</label>
              <Field name="address" type="text" class="form-control form-control-user" placeholder="Enter Address..." />
              <ErrorMessage name="address" class="text-danger p-3" />
            </div>
            <vue-google-autocomplete id="map2" ref="toAddress" classname="form-control" placeholder="Start typing"
              v-on:placechanged="getToData" types="(cities)" country="us">
            </vue-google-autocomplete>
            <div class="form-group">
              <label>Fleet-size</label>
              <Field name="fleet_size" type="text" class="form-control form-control-user"
                placeholder="Enter fleet size..." />
              <ErrorMessage name="fleet_size" class="text-danger p-3" />
            </div>
            <div class="form-group">
              <div v-if="message" class="alert alert-danger" role="alert">
                {{ message }}
              </div>
            </div>
            <button class="btn btn-info col-sm-8 rounded-pill" :disabled="loading">
              <span v-show="loading" class="spinner-border spinner-border-sm pr-2"></span>
              <span>Sign Up</span>
            </button>
          </Form>
          <br />
          <p class="login-wrapper-footer-text">
            Already have an account?
            <a href="/login" class="text-reset">Sign In</a>
          </p>
          <br />
          <div class="container">
            <div class="copyright ml-auto">
              {{ new Date().getFullYear() }} <a href="">OpenRoads</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- </div>  -->
  </main>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import * as yup from "yup";

export default {
  name: "SignIn",
  components: {
    Form,
    Field,
    ErrorMessage,
    VueGoogleAutocomplete,
  },
  data() {
    const schema = yup.object().shape({
      // email: yup
      //     .string()
      //     .required("Email is required!")
      //     .email("Email is invalid!")
      //     .max(50, "Must be maximum 50 characters!"),
      // password: yup.string().required("Password is required!"),
    });

    return {
      loading: false,
      message: "",
      address: "",
      schema,
      title: "",
    };
  },
  methods: {
    async handleSignup(user) {
      this.loading = true;
      this.$store
        .dispatch("/signups", user)
        .then((response) => {
          this.$router.push("/login");
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.message = error.response.data.message;
        });
    },
    getAddressData(addressData, placeResultData, id) {
      this.address = addressData;
    },
  },
  mounted() {
    // To demonstrate functionality of exposed component functions
    // Here we make focus on the user input
    this.$refs.address.focus();
  },
};
</script>
<style scoped src="../../assets/css/Main.css"></style>
