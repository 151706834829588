import api from "./api";

class UtilsService {
  /* innovative */
  getStates() {
    return api.get("/openroads-states");
  }
  getClient(filter) {
    return api.get("/openroads-clients", { params: filter });
  }
  getVehicleStatus() {
    return api.get("/openroads-vehicle-status");
  }
  getaxles() {
    return api.get(`/openroads-axles`);
  }
  getTagTypes() {
    return api.get("/openroads-tag-types");
  }
  getDepartments(client) {
    return api.get(`/openroads-departments?client_id=${client}`);
  }
  getSubDepartments({ dept }) {
    return api.get("/openroads-sub-departments", null, { dept });
  }
  getSubsidiaries(client) {
    return api.get(`/openroads-subsidiaries?client_id=${client}`);
  }
  getAuthUser() {
    return api.get(`/active-user`);
  }
  //modules
  getModules() {
    return api.get("/openroads-modules");
  }
  async getOverviewDept(client) {
    return await api.get(`/openroads-default-department/${client}`);
  }

  getuserdetails(userId) {
    return api.get(`/openroads-contact-user/${userId}`);
  }
  getclientdetails(clientId) {
    return api.get(`/openroads-clients/${clientId}`);
  }
  getClientDefaultUser(clientId) {
    return api.get(`/openroads-default-user/${clientId}`);
  }
  getDept(deptId) {
    return api.get(`/openroads-departments/${deptId}`);
  }
  sendOtp(data) {
    return api.post("/send_email_otp", data);
  }
  resetPassword(data) {
    return api.post("/updatepassword", data);
  }
  getRegion() {
    return api.get(`/openroads-travel-regions`);
  }
}

export default new UtilsService();
