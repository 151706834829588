<template>
  <div
    class="modal fade"
    id="vehicle_upload_modal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="vehicleUploadModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Sign Up</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <Form
            @submit="onSignupSubmit"
            :validation-schema="signup"
            class="form-vertical"
            role="form"
            id="vehicles_upload_form"
          >
            <div class="d-flex">
              <div class="col-6">
                <div class="">
                  <label class="font-weight-bold text-dark">First Name </label>
                </div>
                <div class="mt-2">
                  <Field
                    name="first_name"
                    placeholder="First name..."
                    class="px-2 py-2 w-100 rounded bg-light text-dark border border-bottom-1"
                    type="text"
                  />
                  <ErrorMessage
                    name="first_name"
                    class="text-danger py-3 text-sm"
                  />
                </div>
              </div>
              <div class="col-6">
                <div class="">
                  <label class="font-weight-bold">Last Name </label>
                </div>
                <div class="mt-2">
                  <Field
                    name="last_name"
                    placeholder="Last Name..."
                    class="px-2 py-2 w-100 rounded bg-light text-dark border border-bottom"
                    type="text"
                  />
                  <ErrorMessage
                    name="last_name"
                    class="text-danger py-3 text-sm"
                  />
                </div>
              </div>
            </div>
            <div class="d-flex mt-2">
              <div class="col-6">
                <div class="">
                  <label class="font-weight-bold">Email </label>
                </div>
                <div class="mt-2">
                  <Field
                    name="email"
                    placeholder="Email..."
                    class="px-2 py-2 w-100 rounded bg-light text-dark border border-bottom"
                    type="email"
                  />
                  <ErrorMessage name="email" class="text-danger py-3 text-sm" />
                </div>
              </div>
              <div class="col-6">
                <div class="">
                  <label class="font-weight-bold">Company Name</label>
                </div>
                <div class="mt-2">
                  <input
                    id="company"
                    name="company"
                    placeholder="Company..."
                    class="px-2 py-2 w-100 rounded bg-light text-dark border border-bottom"
                    type="text"
                    ref="company"
                    v-model="company"
                    autocomplete="off"
                  />
                </div>
              </div>
            </div>

            <div class="d-flex mt-2">
              <div class="col-6">
                <div class="">
                  <label class="font-weight-bold">RV Type </label>
                </div>
                <div class="mt-2">
                  <Field
                    name="rv_type"
                    class="form-control input-sm"
                    v-model="rv_type"
                    as="select"
                  >
                    <option value="">Select RV Type</option>
                    <option
                      v-for="RV in RVTags"
                      :value="RV.tag_id"
                      :key="RV.tag_id"
                    >
                      {{ RV.tag_type }}
                    </option>
                  </Field>
                  <ErrorMessage
                    name="rv_type"
                    class="text-danger py-3 text-sm"
                  />
                </div>
              </div>
              <div class="col-6">
                <div class="">
                  <label class="font-weight-bold">Country </label>
                </div>
                <div class="mt-2">
                  <Field
                    name="country"
                    class="form-control input-sm"
                    v-model="country"
                    as="select"
                  >
                    <option value="">Select Country</option>
                    <option value="USA">USA</option>
                    <option value="Canada">Canada</option>
                  </Field>
                  <ErrorMessage
                    name="country"
                    class="text-danger py-3 text-sm"
                  />
                </div>
              </div>
            </div>

            <div class="mt-2 mb-4 d-flex">
              <div class="col-6">
                <div class="">
                  <label class="font-weight-bold">Street Address </label>
                </div>
                <div class="mt-2">
                  <input
                    id="address"
                    name="address"
                    placeholder="Address"
                    class="px-2 py-2 w-100 rounded bg-light text-dark border border-bottom"
                    type="text"
                    ref="address"
                    v-model="address"
                    autocomplete="off"
                  />
                </div>
              </div>
              <div class="col-6">
                <div class="">
                  <label class="font-weight-bold"
                    >Phone <span class="text-danger">(Required)</span></label
                  >
                </div>
                <div class="mt-2">
                  <input
                    name="phone"
                    maxlength="12"
                    :id="inputId"
                    ref="phoneInput"
                    class="px-2 py-2 rounded bg-light text-center text-dark border border-bottom"
                    style="width: 350px"
                    v-model="phoneNumber"
                    @input="sanitizePhoneNumber"
                  />
                  <div v-if="isInputEmpty" class="error-message text-danger">
                    Please enter a phone number
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-2 mb-4 d-flex">
              <div class="col-3">
                <div class="">
                  <label class="font-weight-bold">PO BOX Address</label>
                </div>
                <div class="mt-2">
                  <input
                    id="po_box"
                    name="po_box"
                    placeholder="PO BOX Address..."
                    class="px-2 py-2 w-100 rounded bg-light text-dark border border-bottom"
                    type="text"
                    ref="po_box"
                    v-model="po_box"
                    autocomplete="off"
                  />
                </div>
              </div>

              <div class="col-4">
                <div class="">
                  <label class="font-weight-bold"
                    >Street Name
                    <span class="text-danger">(Required)</span>
                  </label>
                </div>
                <div class="mt-2">
                  <input
                    id="shipping_street"
                    name="shipping_street"
                    placeholder="Shipping street..."
                    class="px-2 py-2 w-100 rounded bg-light text-dark border border-bottom"
                    type="text"
                    ref="shipping_street"
                    v-model="shipping_street"
                  />
                </div>
              </div>
              <div class="col-5">
                <div class="">
                  <label class="font-weight-bold">Select Shipping Speed:</label>
                  <div class="d-flex pl-2">
                    <div
                      class="form-check"
                      style="display: flex; align-items: center"
                    >
                      <input
                        id="overnight_true"
                        name="overnight"
                        style="margin-right: 0.5rem"
                        class="form-check-input"
                        type="radio"
                        value="true"
                        v-model="overnight"
                      />
                      <label class="form-check-label mt-3" for="overnight_true">
                        <span>Overnight Shipping</span>
                      </label>
                    </div>
                    <div
                      class="form-check"
                      style="display: flex; align-items: center"
                    >
                      <input
                        id="overnight_false"
                        name="overnight"
                        style="margin-right: 0.5rem"
                        class="form-check-input"
                        type="radio"
                        value="false"
                        v-model="overnight"
                      />
                      <label
                        class="form-check-label mt-3"
                        for="overnight_false"
                      >
                        <span>Standard Shipping</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="d-flex mt-2 mb-4">
              <div class="col-4">
                <div class="">
                  <label class="font-weight-bold"
                    >City <span class="text-danger">(Required)</span></label
                  >
                </div>
                <div class="mt-2">
                  <input
                    id="shipping_city"
                    name="shipping_city"
                    placeholder="Shipping city..."
                    class="px-2 py-2 w-100 rounded bg-light text-dark border border-bottom"
                    type="text"
                    ref="shipping_city"
                    v-model="shipping_city"
                  />
                </div>
              </div>
              <div class="col-4">
                <div class="">
                  <label class="font-weight-bold"
                    >State <span class="text-danger">(Required)</span></label
                  >
                </div>
                <div class="mt-2">
                  <input
                    id="shipping_state"
                    name="shipping_state"
                    placeholder="Shipping state..."
                    class="px-2 py-2 w-100 rounded bg-light text-dark border border-bottom"
                    type="text"
                    ref="Shipping State"
                    v-model="shipping_state"
                  />
                </div>
              </div>
              <div class="col-4">
                <div class="">
                  <label class="font-weight-bold"
                    >Zip Code <span class="text-danger">(Required)</span></label
                  >
                </div>
                <div class="mt-2">
                  <input
                    id="shipping_zip_code"
                    name="shipping_zip_code"
                    placeholder="Zip Code..."
                    class="px-2 py-2 w-100 rounded bg-light text-dark border border-bottom"
                    type="text"
                    ref="shipping_zip_code"
                    v-model="shipping_zip_code"
                  />
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-danger btn-outline btn-xs"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                type="submit"
                id="submit_btn"
                class="btn btn-primary btn-outline btn-xs"
                @click="submitForm"
              >
                Submit
              </button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  </div>
  <main>
    <div v-if="loading" id="preloader">
      <div class="preloader">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
    <div class="container-fluid 100vh">
      <div class="row">
        <div class="col-sm-4 login-section-wrapper">
          <div class="brand-wrapper"></div>
          <div class="login-wrapper my-auto">
            <h2 class="login-title">Log in</h2>
            <p class="text-gray-400"><em>Log-in to get started</em></p>
            <Form
              @submit="handleLogin"
              :validation-schema="schema"
              class="user"
            >
              <div class="form-group">
                <Field
                  name="email"
                  type="text"
                  class="form-control form-control-user"
                  placeholder="Enter Email ..."
                />
                <ErrorMessage name="email" class="text-danger p-3" />
              </div>
              <div class="form-group">
                <Field
                  name="password"
                  type="password"
                  class="form-control form-control-user"
                  placeholder="Password"
                />
                <ErrorMessage name="password" class="text-danger p-3" />
              </div>
              <div class="form-group">
                <div v-if="message" class="alert alert-danger" role="alert">
                  {{ message }}
                </div>
              </div>
              <button
                class="btn btn-info col-sm-8 rounded-pill"
                :disabled="loading"
              >
                <span
                  v-show="loading"
                  class="spinner-border spinner-border-sm pr-2"
                ></span>
                <span>Log In</span>
              </button>
            </Form>
            <br />
            <router-link class="nav-link mb-0 text-sm mx-auto" to="/reset"
              >Forgot password?</router-link
            >
            <p class="login-wrapper-footer-text">
              Don't have an account?
              <button
                data-toggle="modal"
                data-target="#vehicle_upload_modal"
                class="text-primary underline bg-white border border-white special"
              >
                <u>Register here</u>
              </button>
            </p>
            <br />
            <div class="container">
              <div class="copyright ml-auto">
                {{ new Date().getFullYear() }}
                <a href="">Open Roads</a>
              </div>
            </div>
          </div>
        </div>
        <div
          class="col-sm-8 px-0 d-none d-sm-block"
          style="
            background-image: url(https://crm.innovativetoll.com/assets/images/login_bg.png);
            display: block;
            background-size: cover;
            background-repeat: no-repeat;
            overflow: auto;
          "
        >
          <div class="maintxt">
            <h1 class=""><b>OPEN ROADS</b></h1>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<style scoped src="../../assets/css/Main.css">
@media (max-width: 768px) {
  .col-6 input {
    width: 100%;
  }
}

@media (min-width: 769px) {
  .col-6 input {
    width: 350px;
  }
}

.custom-width {
  width: 300px;
}

.special {
  cursor: crosshair;
}

.pac-container {
  z-index: 1051 !important;
}
</style>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import AuthService from "@/services/auth.service";
import { Loader } from "@googlemaps/js-api-loader";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import VehicleService from "@/services/vehicle.service";
import "maz-ui/css/main.css";

import * as yup from "yup";
import MazBtn from "maz-ui/components/MazBtn";
import MazInput from "maz-ui/components/MazInput";

import MazPhoneNumberInput from "maz-ui/components/MazPhoneNumberInput";
import { ref } from "vue";
const phoneNumber = ref();
const results = ref();
export default {
  name: "SignIn",
  components: {
    Form,
    Field,
    ErrorMessage,
    MazPhoneNumberInput,
    VueGoogleAutocomplete,
  },
  data() {
    const schema = yup.object().shape({
      email: yup
        .string()
        .required("Email is required!")
        .email("Email is invalid!")
        .max(50, "Must be maximum 50 characters!"),
      password: yup.string().required("Password is required!"),
    });
    const signup = yup.object().shape({
      email: yup
        .string()
        .required("Email is required!")
        .email("Email is invalid!")
        .max(50, "Must be maximum 50 characters!"),
      first_name: yup
        .string("First name is required!")
        .required("First name is required!"),
      last_name: yup
        .string("Last name is required!")
        .required("Last name is required!"),
      rv_type: yup
        .string("RV Type is required!")
        .required("RV Type is required!"),
      country: yup
        .string("Country is required!")
        .required("Country is required!"),
    });

    return {
      loading: false,
      showmodel: false,
      message: "",
      schema,
      signup,
      title: "",
      shipping_city: "",
      shipping_state: "",
      shipping_street: "",
      shipping_zip_code: "",
      address: "",
      rv_type: "",
      po_box: "",
      RVTags: [],
      inputId: "phone",
      phoneNumber: "",
      countryCode: "",
      phoneCode: "",
      isInputEmpty: false,
      // showMessage: false,
    };
  },

  methods: {
    initIntlTelInput() {
      const phoneInputField = this.$refs.phoneInput;
      const phoneInput = window.intlTelInput(phoneInputField, {
        utilsScript:
          "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js",
        onlyCountries: ["us", "ca"],
      });

      // Listen for changes in the input field and update the phone number
      phoneInputField.addEventListener("input", () => {
        const selectedCountryData = phoneInput.getSelectedCountryData();
        const countryCode = selectedCountryData.dialCode;

        const phoneNumber = phoneInput.getNumber();

        // Update the phone number without the country code
        this.phoneNumber = phoneNumber;
        this.phoneCode = phoneNumber + countryCode;
      });
    },
    sanitizePhoneNumber() {
      // Get the current input value
      let inputValue = this.phoneNumber;

      // Remove non-numeric characters
      inputValue = inputValue.replace(/\D/g, "");

      // Limit the input to your desired maximum length
      if (inputValue.length > 10) {
        inputValue = inputValue.substring(0, 10);
      }

      // Update the input field with the sanitized value
      this.phoneNumber = inputValue;
      this.isInputEmpty = inputValue.trim() === "";
    },
    submitForm() {
      this.isInputEmpty;
    },
    async handleLogin(user) {
      this.loading = true;
      this.$store
        .dispatch("auth/login", user)
        .then((response) => {
          this.$router.push("/dashboard");
          this.loading = false;
        })
        .catch((error) => {
          this.$swal({
            title:
              error.response.data.error === "invalid_grant"
                ? error.response.data.message
                : error.response.data.error,
            position: "top-end",
            icon: "warning",
            showConfirmButton: false,
            timer: 6000,
          });
          this.loading = false;
          // this.showMessage = true;
        });
    },
    onSignupSubmit(user) {
      const shipping_street = this.shipping_street?.trim();
      const shipping_city = this.shipping_city?.trim();
      const shipping_state = this.shipping_state?.trim();
      const shipping_zip_code = this.shipping_zip_code?.trim();
      const phone = this.phoneNumber?.trim();
      user["shipping_state"] = this.shipping_state;
      user["shipping_city"] = this.shipping_city;
      user["shipping_street"] = this.shipping_street;
      user["shipping_zip_code"] = this.shipping_zip_code;
      user["phone"] = this.phoneCode;
      user["address"] = this.address;
      if (
        !shipping_street ||
        !shipping_city ||
        !shipping_state ||
        !phone ||
        !shipping_zip_code
      ) {
        this.$swal({
          title: "Please fill out all required fields.",
          position: "top-end",
          icon: "error",
          showConfirmButton: false,
          timer: 3000,
        });
        return;
      }
      AuthService.signup(user)
        .then((response) => {
          if (response.data.data) {
            this.$swal({
              title:
                "Thank you for submitting your details. Our team will get back to you within 24 - 48 hours.",
              position: "top-end",
              icon: "success",
              showConfirmButton: false,
              timer: 5000,
            });
            $("#vehicle_upload_modal").modal("hide");
            this.showmodel = false;
          } else {
            this.$swal({
              title: response.data.error,
              position: "top-end",
              icon: "warning",
              showConfirmButton: false,
              timer: 3000,
            });
            this.showmodel = false;
          }
        })
        .catch((err) => {
          this.$swal({
            title: err.response.data.error,
            position: "top-end",
            icon: "warning",
            showConfirmButton: false,
            timer: 6000,
          });
        });
      // send post request to api
      // close modal
    },
    tougleModal() {
      this.titleModal = "Add User";
    },
  },
  created() {
    VehicleService.getRVTypes().then((response) => {
      this.RVTags = response.data.data;
    });
  },
  mounted() {
    this.initIntlTelInput();
    var pacContainerInitialized = false;
    $("#address").keypress(function () {
      if (!pacContainerInitialized) {
        $(".pac-container").css("z-index", "9999");
        pacContainerInitialized = true;
      }
    });

    var address = document.getElementById("address");
    const autocomplete = new google.maps.places.Autocomplete(address, {
      componentRestrictions: { country: ["us", "ca"] },
      fields: ["address_components"],
      types: ["address"],
    });

    autocomplete.addListener("place_changed", () => {
      let place = autocomplete.getPlace();
      this.address = "";
      this.shipping_state = "";
      this.shipping_city = "";
      this.shipping_street = "";
      this.shipping_zip_code = "";
      let postcode = "";
      let address = "";
      let city = "";
      let street = "";
      let state = "";
      for (const component of place.address_components) {
        // @ts-ignore remove once typings fixed
        const componentType = component.types[0];

        switch (componentType) {
          case "street_number": {
            street = `${component.long_name} ${street}`;
            break;
          }

          case "route": {
            street += component.short_name;
            break;
          }

          case "postal_code": {
            postcode = `${component.long_name}${postcode}`;
            break;
          }
          case "country": {
            address = `${street}  ${city}, ${state}`;
            break;
          }

          case "locality":
            city = component.long_name;
            break;
          case "administrative_area_level_1": {
            state = component.short_name;
            break;
          }
          case "route":
            state = component.long_name;
            break;
        }
      }

      this.address = address;
      this.shipping_state = state;
      this.shipping_city = city;
      this.shipping_zip_code = postcode;
      this.shipping_street = street;
    });
  },
};
</script>
