import { createWebHistory, createRouter } from "vue-router";
import SignIn from "@/components/auth/SignIn.vue";
import Register from "@/components/auth/SignUp.vue";
import Hub from "@/components/hub.vue";

const authGuard = (to, from, next) => {
  var isAuthenticated = false;
  if (localStorage.getItem("user")) isAuthenticated = true;
  else isAuthenticated = false;
  if (isAuthenticated) next();
  else next("/login");
};

const routes = [
  { path: "/", component: SignIn, meta: { title: "Sign In" } },
  { path: "/login", component: SignIn, meta: { title: "Sign In" } },
  { path: "/hub", component: Hub, meta: { title: "hub" } },
  { path: "/register", component: Register, meta: { title: "Register" } },
  {
    path: "/dashboard",
    component: () => import("@/components/dashboard.vue"),
    beforeEnter: authGuard,
    meta: { title: "Dashboard" },
  },
  {
    path: "/tow-vehicle",
    component: () => import("@/components/towVehicle.vue"),
    beforeEnter: authGuard,
    meta: { title: "Tow Vehicles" },
  },
  {
    path: "/vehicles",
    component: () => import("@/components/Vehicles.vue"),
    beforeEnter: authGuard,
    meta: { title: "Vehicles" },
  },
  {
    path: "/credit-cards",
    component: () => import("@/components/CreditCard.vue"),
    beforeEnter: authGuard,
    meta: { title: "Credit Cards" },
  },
  {
    path: "/invoice-uploads",
    component: () => import("@/components/invoiceuploads.vue"),
    beforeEnter: authGuard,
    meta: { title: "Invoice Uploads" },
  },
  {
    path: "/citation-uploads",
    component: () => import("@/components/citationsuploads.vue"),
    beforeEnter: authGuard,
    meta: { title: "Invoice Uploads" },
  },

  {
    path: "/transactions",
    component: () => import("@/components/transactions.vue"),
    beforeEnter: authGuard,
    meta: { title: "Transactions" },
  },
  {
    path: "/transponders",
    component: () => import("@/components/transponders.vue"),
    beforeEnter: authGuard,
    meta: { title: "Transponders" },
  },
  {
    path: "/transponders-order",
    component: () => import("@/components/ordertransponders.vue"),
    beforeEnter: authGuard,
    meta: { title: "Transponders" },
  },
  {
    path: "/allusers",
    component: () => import("@/components/allUsers.vue"),
    beforeEnter: authGuard,
    meta: { title: "allUsers" },
  },
  {
    path: "/disputes",
    component: () => import("@/components/dispute.vue"),
    beforeEnter: authGuard,
    meta: { title: "Disputes" },
  },
  {
    path: "/invoices",
    component: () => import("@/components/invoices.vue"),
    beforeEnter: authGuard,
    meta: { title: "Invoices" },
  },
  {
    path: "/citations",
    component: () => import("@/components/citations.vue"),
    beforeEnter: authGuard,
    meta: { title: "Citations" },
  },
  {
    path: "/reset",
    component: () => import("@/components/auth/ResetPassword.vue"),
    // beforeEnter: authGuard,
    meta: { title: "Reset" },
  },
  {
    path: "/resetUser",
    component: () => import("@/components/auth/ResetPasswordLoggedIn.vue"),
    beforeEnter: authGuard,
    meta: { title: "Reset" },
  },
  {
    path: "/profile",
    component: () => import("@/components/profile.vue"),
    beforeEnter: authGuard,
    meta: { title: "profile" },
  },
  {
    path: "/statements",
    component: () => import("@/components/statements.vue"),
    beforeEnter: authGuard,
    meta: { title: "statements" },
  },
  {
    path: "/changePassword",
    component: () => import("@/components/changePassword.vue"),
    beforeEnter: authGuard,
    meta: { title: "changePassword" },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
