import api from "./api";
const clientDetails =
  localStorage.getItem("hasAccess") === null
    ? ""
    : `?client_id=${
        JSON.parse(localStorage.getItem("contact")).client_id
      }&hasAccess=${JSON.parse(localStorage.getItem("hasAccess"))}`;

class VehicleService {
  getVehicles() {
    return api.get(`/openroads-vehicles${clientDetails}`);
  }
  getTowVehicles() {
    return api.get(`/tow-vehicles${clientDetails}`);
  }
  getTowVehicleID(vehicle_id) {
    return api.get(`/tow-vehicles/${vehicle_id}${clientDetails}`);
  }
  getRVTypes() {
    return api.get(`/openroads-tag-types`);
  }
  getVehicle(vehicle_id) {
    return api.get(`/openroads-vehicles/${vehicle_id}${clientDetails}`);
  }
  getClientVehicles(data) {
    return api.get(`/openroads-vehicles${clientDetails}`, { params: data });
  }
  getAxles() {
    return api.get(`/openroads-axles`);
  }
  getLogs(value) {
    return api.get(`/openroads-client-logs${clientDetails}`);
  }
  getVehicleDumps() {
    return api.get(`/openroads-vehicle-dumps${clientDetails}`);
  }
  addVehicle(data) {
    return api.post(`/openroads-vehicles${clientDetails}`, data);
  }
  addTowVehicle(data) {
    return api.post(`/tow-vehicles${clientDetails}`, data);
  }
  uploadVehicleDumps(data) {
    return api.post(`/openroads-vehicle-dumps${clientDetails}`, data);
  }
  uploadClientAssets(data) {
    return api.post(`/openroads-client-uploads${clientDetails}`, data);
  }
  updateVehicles(vehicle_id, dataE) {
    return api.post(`/openroads-vehicles/${vehicle_id}?_method=put`, dataE);
  }
  updateTowVehicles(vehicle_id, dataE) {
    return api.post(`/tow-vehicles/${vehicle_id}?_method=put`, dataE);
  }
  deleteVehicle(vehicle_id) {
    return api.delete(`/openroads-vehicles/${vehicle_id}${clientDetails}`);
  }
  deleteTowVehicle(vehicle_id) {
    return api.delete(`/tow-vehicles/${vehicle_id}${clientDetails}`);
  }
}
export default new VehicleService();
